import { trpc } from '@/utils/trpc';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/shadcn/dialog';
import { Button } from '@/components/shadcn/button';
import { useNavigate } from '@tanstack/react-router';
import {
  DOWNGRADE_COUPON,
  PlanDetail,
  plans,
  PRO_PLAN,
  STANDARD_PLAN,
} from '@/utils/data/plans.ts';
import { useEffect, useState } from 'react';
import { Loader } from '@/components/custom-components/Loader';
import { Badge } from '@/components/shadcn/badge';
import { Input } from '@/components/shadcn/input.tsx';
import { CheckCircle, CreditCard, User } from '@mynaui/icons-react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form.tsx';
import showToastNotification from '@/hooks/useShowToast';
import { CheckoutUpgradeSubscription } from '@/components/CheckoutSubscriptionPlan.tsx';
import { WorkOSUser } from '@/routes/login.tsx';
import { Checkbox } from './shadcn/checkbox';
import { Text } from './custom-components';
import {
  Sidebar,
  SidebarContent,
  SidebarGroup,
  SidebarGroupContent,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarProvider,
} from '@/components/shadcn/sidebar.tsx';
import { Tabs, TabsList, TabsTrigger } from '@/components/shadcn/tabs.tsx';

const sidebarOptions = {
  nav: [
    { name: 'My Account', icon: User },
    { name: 'Plan and Billing', icon: CreditCard },
  ],
};

export const UserProfileDialog = ({
  open,
  onOpenChange,
}: {
  open: boolean;
  onOpenChange: () => void;
}) => {
  const navigate = useNavigate();

  const { data: userData, isLoading: fetchingUserData } =
    trpc.me.useQuery(null);

  const { data: permissionData, isLoading: fetchingPermissions } =
    trpc.permissions.useQuery(null, {});

  const { mutateAsync: logoutUser } = trpc.logoutUser.useMutation();
  const {
    mutateAsync: changeNewsLetterSubscription,
    isPending: isChangingSub,
  } = trpc.changeNewsLetterSubscription.useMutation();
  const [period, setPeriod] = useState<'monthly' | 'annually'>('monthly');

  const [edit, setEdit] = useState<boolean>(false);
  const [upgradeOpen, setUpgradeOpen] = useState<boolean>(false);
  const [newsLetterSubscribed, setNewsLetterSubscribed] =
    useState<boolean>(false);
  const [selected, setSelected] = useState<PlanDetail | null>(null);
  const [subSection, setSubSection] = useState<string>(
    sidebarOptions.nav[0].name,
  );

  const [subscriptionStatus, setSubscriptionStatus] = useState<
    'onFreeTrial' | 'hasPlan' | 'hasNoPlan' | undefined
  >(undefined);

  const { mutate: resetUserPassword, isPending: sendingPasswordResetEmail } =
    trpc.sendResetPasswordEmail.useMutation();

  const { mutate: upgradeUserNewPlan } =
    trpc.upgradeUserWithActivePlan.useMutation({
      onSuccess: async () => {
        showToastNotification('success', {
          message: 'Plan upgrade successfully!',
        });
      },
      onError: (error) => {
        showToastNotification('error', {
          message: error.message,
        });
      },
    });

  async function onResetPassword({ email }: { email: string }) {
    if (email) {
      try {
        resetUserPassword(
          { email },
          {
            onSuccess: () => {
              // let's keep the email in
              showToastNotification('success', {
                message: 'Request sent successfully!',
                description:
                  'We have sent you an email to complete the password reset',
              });
            },
            onError: (error) => {
              showToastNotification('error', {
                message: error.message,
              });
            },
          },
        );
      } catch (e) {
        console.log(e);
      }
    }
  }

  const { data: chargebeeSyncStatus } = trpc.getIsUserSynced.useQuery(
    undefined,
    {
      refetchOnWindowFocus: false,
    },
  );

  const { mutate: openPortalSessionUrl, isPending: fetchingPortalSessionUrl } =
    trpc.getPortalSessionUrl.useMutation({
      onSuccess: async (data) => {
        if (data) {
          window.location.href = data;
        }
      },
    });

  const { refetch: getUserActiveSubscriptions } =
    trpc.getUserActiveSubscriptions.useQuery(undefined, {
      enabled: false,
      refetchOnWindowFocus: false,
    });

  const {
    mutate: generateCustomerChurnKeyHash,
    isPending: generatingChurnkeyHash,
  } = trpc.generateCustomerChurnKeyHash.useMutation({
    onSuccess: async (data) => {
      if (data && typeof window !== 'undefined' && permissionData) {
        getUserActiveSubscriptions().then((res) => {
          if (res && res.data) {
            window.churnkey.init('show', {
              subscriptionId: res.data[0].subscriptionId || '',
              customerId: permissionData.ID,
              authHash: data,
              appId: import.meta.env.VITE_CHURNKEY_APP_ID,
              mode: 'live',
              provider: 'chargebee',
              record: true,
              handleSupportRequest: (customer) => {
                console.log(customer);
                if (window.Intercom) {
                  window.Intercom(
                    'showNewMessage',
                    'Attention: Offboarding Customer Needs Help ASAP.\n',
                  );
                }
                window.churnkey.hide();
              },
              handlePlanChange: () => {
                upgradeUserNewPlan({
                  plans:
                    // if pro monthly or yearly, downgrade to the respective standard

                    permissionData?.usersPlans[0] === PRO_PLAN.plan_id ||
                    permissionData?.usersPlans[0] === PRO_PLAN.no_trial_plan_id
                      ? [STANDARD_PLAN.plan_id]
                      : [STANDARD_PLAN.yearly_plan_id],

                  coupons: [DOWNGRADE_COUPON],
                  chargebeeToken: undefined,
                  billingAddress: undefined,
                });
              },
            });
            setTimeout(() => {
              onOpenChange();
            }, 3000);
          }
        });
      }
    },
  });

  useEffect(() => {
    if (userData) {
      setNewsLetterSubscribed(userData.subscribed ?? false);
    }
  }, [userData]);

  useEffect(() => {
    if (permissionData && plans) {
      if (
        permissionData.userCanAccessEverything &&
        permissionData.usersPlans.length === 0
      )
        setSubscriptionStatus('onFreeTrial');
      else if (
        permissionData.usersPlans.length > 0 &&
        permissionData.usersPlans.some((plan) =>
          [
            STANDARD_PLAN.plan_id,
            STANDARD_PLAN.no_trial_plan_id,
            PRO_PLAN.plan_id,
            PRO_PLAN.no_trial_plan_id,
          ].includes(plan),
        )
      ) {
        setSubscriptionStatus('hasPlan');
      } else {
        setSubscriptionStatus('hasNoPlan');
      }
    }
  }, [permissionData]);

  return (
    <Dialog
      open={open}
      onOpenChange={edit ? () => setEdit(false) : onOpenChange}
    >
      {fetchingPermissions || fetchingUserData ? (
        <DialogContent
          className={'border-0 rounded-lg max-w-lg px-6'}
          onInteractOutside={(e) => {
            e.preventDefault();
          }}
        >
          <div className="flex justify-center items-center w-full py-6 h-14">
            <Loader />
          </div>
        </DialogContent>
      ) : upgradeOpen && selected ? (
        <DialogContent className={'overflow-y-auto max-h-screen'}>
          <CheckoutUpgradeSubscription
            userHasPlan={true}
            selectedPlan={selected}
          />
        </DialogContent>
      ) : (
        <DialogContent
          className={`overflow-hidden p-0 md:max-h-[500px] md:max-w-[700px] lg:max-w-[800px]`}
          onInteractOutside={(e) => {
            e.preventDefault();
          }}
        >
          <SidebarProvider className="items-start">
            <Sidebar collapsible="none" className="hidden md:flex">
              <SidebarContent>
                <SidebarGroup className={'flex flex-col justify-between '}>
                  <SidebarGroupContent className="md:h-[28rem]">
                    <SidebarMenu>
                      {sidebarOptions.nav.map((item) => (
                        <SidebarMenuItem
                          className={'cursor-pointer'}
                          key={item.name}
                        >
                          <SidebarMenuButton
                            asChild
                            isActive={item.name === subSection}
                            onClick={() => setSubSection(item.name)}
                          >
                            <span className={'flex gap-2 items-center'}>
                              <item.icon />
                              <span>{item.name}</span>
                            </span>
                          </SidebarMenuButton>
                        </SidebarMenuItem>
                      ))}
                    </SidebarMenu>
                  </SidebarGroupContent>
                  <SidebarGroupContent>
                    <SidebarMenu>
                      <SidebarMenuItem>
                        <SidebarMenuButton
                          className={'cursor-pointer'}
                          asChild
                          onClick={() => {
                            logoutUser().then(() => {
                              if (typeof localStorage !== 'undefined') {
                                localStorage.clear();
                              }
                              caches.keys().then((names) => {
                                names.forEach((name) => {
                                  caches.delete(name);
                                });
                              });
                              navigate({ to: '/login' });
                            });
                          }}
                        >
                          <span className={''}>Log out</span>
                        </SidebarMenuButton>
                      </SidebarMenuItem>
                    </SidebarMenu>
                  </SidebarGroupContent>
                </SidebarGroup>
              </SidebarContent>
            </Sidebar>
            <main className="flex h-[480px] flex-1 flex-col overflow-hidden">
              <div className="flex flex-1 flex-col gap-4 overflow-y-auto p-6 pt-8">
                {subSection === 'Plan and Billing' ? (
                  <div className={'space-y-6'}>
                    <div className={'space-y-2'}>
                      <h5 className={'font-semibold text-2xl'}>Your Plan</h5>
                      <p className={'text-thememutedforeground'}>
                        {subscriptionStatus === 'hasNoPlan'
                          ? 'You are not currently on a Paid Plan. Choose a plan from below.'
                          : null}
                      </p>
                    </div>
                    <div className={'space-y-3'}>
                      <div
                        className={'flex justify-between gap-5 items-center'}
                      >
                        <span className={'font-semibold'}>Available Plans</span>
                        <Tabs defaultValue={period}>
                          <TabsList className={'rounded-full'}>
                            <TabsTrigger
                              className={'rounded-full'}
                              value={'monthly'}
                              onClick={() => setPeriod('monthly')}
                            >
                              Pay Monthly
                            </TabsTrigger>
                            <TabsTrigger
                              className={'rounded-full flex gap-1.5'}
                              value={'annually'}
                              onClick={() => setPeriod('annually')}
                            >
                              <span>Pay Yearly</span>{' '}
                              <span className={'text-themedestructive'}>
                                Save 50%
                              </span>
                            </TabsTrigger>
                          </TabsList>
                        </Tabs>
                      </div>
                      {[STANDARD_PLAN, PRO_PLAN].map((plan, index) => (
                        <UpgradeBox
                          boxData={plan}
                          key={index}
                          period={period}
                          userPlans={permissionData?.usersPlans ?? []}
                          descriptionDetailsToShow={'features'}
                          setUpgradeOpen={setUpgradeOpen}
                          setSelected={setSelected}
                        />
                      ))}
                    </div>
                    <div>
                      {chargebeeSyncStatus &&
                        subscriptionStatus === 'hasPlan' && (
                          <div
                            className={
                              'flex justify-between items-center gap-1.5 h-9'
                            }
                          >
                            {fetchingPortalSessionUrl ||
                            generatingChurnkeyHash ? (
                              <span className="font-medium text-sm leading-5 text-thememutedforeground tracking-wide	">
                                Loading...
                              </span>
                            ) : (
                              <div className="flex gap-2">
                                <Button
                                  size={'sm'}
                                  onClick={() => openPortalSessionUrl()}
                                >
                                  Manage Billing
                                </Button>
                                <Button
                                  variant={'outline'}
                                  onClick={() => generateCustomerChurnKeyHash()}
                                >
                                  Cancel
                                </Button>
                              </div>
                            )}
                          </div>
                        )}
                    </div>
                    <div>
                      <p
                        className={
                          'text-sm font-light text-thememutedforeground'
                        }
                      >
                        Learn more about{' '}
                        <a
                          href={'https://creativeos.com/#pricing'}
                          className={'underline'}
                        >
                          all plans & features
                        </a>
                        .
                      </p>
                    </div>
                  </div>
                ) : (
                  <div>
                    {edit && userData ? (
                      <EditProfile userData={userData} setEdit={setEdit} />
                    ) : (
                      <div
                        className={`flex flex-col gap-6 ${!(edit || upgradeOpen) ? '' : 'hidden'}`}
                      >
                        <div className="gap-3 flex flex-col">
                          <div
                            className={'flex justify-start items-start gap-1.5'}
                          >
                            <img
                              alt={''}
                              src={userData?.avatar || '/bg_gradient.jpg'}
                              className={
                                'rounded-full cursor-pointer shrink-0 bg-[#A259FF] w-9 h-9'
                              }
                            />
                            <span className="font-semibold text-2xl leading-8 text-themeforeground">
                              {`${
                                userData?.firstName || userData?.lastName
                                  ? `${userData.firstName ?? ''} ${userData.lastName ?? ''}`
                                  : ''
                              }`.trim()}
                            </span>
                          </div>
                          <div
                            className={
                              'flex justify-between items-center gap-1.5 h-9'
                            }
                          >
                            <span className="font-semibold text-base leading-6 text-themeforeground">
                              Account
                            </span>
                            <span
                              onClick={() => setEdit(true)}
                              className="font-medium text-sm leading-5 text-themeforeground underline underline-offset-1 cursor-pointer tracking-wide	"
                            >
                              Edit
                            </span>
                          </div>
                          <div
                            className={'flex justify-start items-start gap-1.5'}
                          >
                            <span className="font-normal text-base leading-6 text-thememutedforeground w-20 min-w-20">
                              Name
                            </span>
                            <span className="font-medium text-base leading-5 text-themeforeground">
                              {`${
                                userData?.firstName || userData?.lastName
                                  ? `${userData.firstName ?? ''} ${userData.lastName ?? ''}`
                                  : ''
                              }`.trim()}
                            </span>
                          </div>
                          <div
                            className={'flex justify-start items-start gap-1.5'}
                          >
                            <span className="font-normal text-base leading-6 text-thememutedforeground w-20 min-w-20">
                              Email
                            </span>
                            <span className="font-medium text-base leading-5 text-themeforeground">
                              {`${userData?.email ?? ''}`.trim()}
                            </span>
                          </div>
                          <div
                            className={
                              'flex justify-between items-center gap-1.5 h-9'
                            }
                          >
                            <span className="font-semibold text-base leading-6 text-themeforeground">
                              Communication
                            </span>
                          </div>
                          {userData && (
                            <div className="flex gap-2 items-center">
                              <Checkbox
                                checked={newsLetterSubscribed}
                                onCheckedChange={(checked) => {
                                  setNewsLetterSubscribed(!!checked);
                                  changeNewsLetterSubscription({
                                    subscribed: !!checked,
                                  });
                                }}
                                disabled={isChangingSub}
                              />
                              <Text weight={'medium'} size={'sm'}>
                                Receive useful tips, newsletters, and promotions
                                via e-mail
                              </Text>
                            </div>
                          )}
                        </div>
                        <div className={'flex gap-2 mb-4'}>
                          <Button
                            variant={'outline'}
                            className="h-8"
                            onClick={() =>
                              onResetPassword({
                                email: userData?.email ?? '',
                              })
                            }
                            disabled={
                              sendingPasswordResetEmail || !userData?.email
                            }
                            loading={sendingPasswordResetEmail}
                          >
                            {sendingPasswordResetEmail
                              ? 'Resetting Password...'
                              : 'Reset Password'}
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </main>
          </SidebarProvider>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default function UpgradeBox({
  boxData,
  userPlans,
  period,
  descriptionDetailsToShow,
  setSelected,
  setUpgradeOpen,
}: {
  boxData: PlanDetail;
  userPlans: string[];
  period: string;
  descriptionDetailsToShow: 'description' | 'features';
  setUpgradeOpen: (opened: boolean) => void;
  setSelected: (plan: PlanDetail) => void;
}) {
  const userIsOnProPlan =
    userPlans.includes(PRO_PLAN.plan_id) ||
    userPlans.includes(PRO_PLAN.no_trial_plan_id) ||
    userPlans.includes(PRO_PLAN.yearly_plan_id);

  const isProPlanBox =
    boxData.plan_id === PRO_PLAN.plan_id ||
    boxData.plan_id === PRO_PLAN.no_trial_plan_id ||
    boxData.plan_id === PRO_PLAN.yearly_plan_id;

  const isStandardPlanBox =
    boxData.plan_id === STANDARD_PLAN.plan_id ||
    boxData.plan_id === STANDARD_PLAN.no_trial_plan_id ||
    boxData.plan_id === STANDARD_PLAN.yearly_plan_id;

  const userPlansContainCurrentBoxDataPlans =
    userPlans.includes(boxData.plan_id) ||
    userPlans.includes(boxData.no_trial_plan_id) ||
    userPlans.includes(boxData.yearly_plan_id);

  return userIsOnProPlan && !isProPlanBox ? (
    <></>
  ) : (
    <div
      className={`p-4 border-2	rounded-lg ${userPlansContainCurrentBoxDataPlans ? 'border-themeborder' : 'border-themedestructive'} gap-1`}
    >
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-2">
          <span className="text-themeforeground text-base leading-6 font-medium">
            {boxData.name}
          </span>
          {isProPlanBox && !userPlansContainCurrentBoxDataPlans && (
            <Badge className="text-themedestructive text-xs leading-4 font-medium bg-purple-100 hover:bg-purple-100">
              {userPlans.length == 0 ? 'Recommended' : 'Premium'}
            </Badge>
          )}
          {userPlansContainCurrentBoxDataPlans && (
            <Badge className="text-themeforeground hover:text-themeforeground text-xs leading-4 font-medium bg-thememuted hover:bg-thememuted">
              Current Plan
            </Badge>
          )}
        </div>
        <div className="flex items-center gap-2">
          <span className="text-themeforeground text-base leading-6 font-bold">
            {period === 'monthly' ? boxData.price : boxData.annual_price} /{' '}
            {period === 'monthly' ? 'mo' : 'yr'}
          </span>
          {!userPlansContainCurrentBoxDataPlans && (
            <Button
              variant={'destructivePurple'}
              size={'sm'}
              onClick={() => {
                setSelected(boxData);
                setUpgradeOpen(true);
              }}
            >
              {isProPlanBox && userPlans.length == 0
                ? 'Get Pro'
                : isStandardPlanBox && userPlans.length == 0
                  ? 'Get Started'
                  : 'Upgrade'}
            </Button>
          )}
        </div>
      </div>
      {!userPlansContainCurrentBoxDataPlans && (
        <div>
          {descriptionDetailsToShow === 'features' ? (
            <div className="flex flex-col gap-2">
              <span className="text-sm font-normal leading-5 text-themeforeground">
                {period === 'monthly'
                  ? boxData.price
                  : boxData.annual_monthly_price}{' '}
                per month, cancel anytime{' '}
              </span>
              <>
                {boxData.features.map((feature, index) => (
                  <div
                    key={index}
                    className="flex gap-1 justify-start items-center text-thememutedforeground"
                  >
                    <CheckCircle className="w-4 h-4" />
                    <span className="text-sm font-normal leading-5 ">
                      {feature}
                    </span>
                  </div>
                ))}
              </>
            </div>
          ) : (
            <p className={'text-sm font-light'}>{boxData.description}</p>
          )}
        </div>
      )}
    </div>
  );
}

function EditProfile({
  userData,
  setEdit,
}: {
  userData: WorkOSUser;
  setEdit: (status: boolean) => void;
}) {
  const utils = trpc.useUtils();

  const { mutate: editUserProfile, isPending: editIsPending } =
    trpc.updateUserProfile.useMutation({
      onSuccess: async () => {
        showToastNotification('success', {
          message: 'Profile details successfully updated!',
        });

        await utils.me
          .invalidate(undefined, { refetchType: 'all' })
          .then(() => {
            setEdit(false);
          });

        if (typeof localStorage !== 'undefined') {
          const userDataStringified = localStorage.getItem('_wos_user');
          const userData: {
            object: 'user';
            email: string;
            id: string;
            createdAt: string;
            updatedAt: string;
            firstName: string | null;
            lastName: string | null;
            emailVerified: boolean;
            profilePictureUrl: string | null;
          } = userDataStringified ? JSON.parse(userDataStringified) : {};
          localStorage.setItem(
            '_wos_user',
            JSON.stringify({
              ...userData,
              firstName: form.getValues('firstName'),
              lastName: form.getValues('lastName'),
            }),
          );
        }
      },
    });
  const formSchema = z.object({
    firstName: z.string().min(1, 'First name is required'),
    lastName: z.string().min(1, 'Last name is required'),
    email: z.string().optional(),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
    },
  });

  const handleSubmitEditProfile = async (
    values: z.infer<typeof formSchema>,
  ) => {
    try {
      if (userData) {
        const { firstName, lastName } = values;
        editUserProfile({ data: { firstName, lastName }, userId: userData.id });
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (userData) {
      form.reset({
        firstName: userData.firstName || '',
        lastName: userData.lastName || '',
        email: userData.email || '',
      });
    }
  }, [userData, form]);

  return (
    <div className={`flex flex-col gap-6`}>
      <DialogHeader>
        <DialogTitle className="">
          <div className="flex justify-between mt-10">
            <div className={'flex justify-start items-center gap-1.5  w-fit '}>
              <div className={'flex w-9 h-9'}>
                <img
                  alt={''}
                  src={userData?.avatar ?? '/bg_gradient.jpg'}
                  className={
                    'rounded-full cursor-pointer shrink-0 bg-[#A259FF] w-9 h-9'
                  }
                />
              </div>
              <span
                className={
                  'max-w-[15rem] text-themeforeground h-full font-semibold text-2xl leading-8 truncate overflow-ellipsis overflow-hidden'
                }
              >
                {`${
                  userData?.firstName || userData?.lastName
                    ? `${userData.firstName ?? ''} ${userData.lastName ?? ''}`
                    : ''
                }`.trim()}
              </span>
            </div>
          </div>
        </DialogTitle>
      </DialogHeader>
      <div className="gap-3 flex flex-col max-w-lg">
        <div className={'flex justify-between items-center gap-1.5 h-9'}>
          <span className="font-semibold text-base leading-6 text-themeforeground">
            Account
          </span>
        </div>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(handleSubmitEditProfile)}
            className={''}
          >
            <div className={'flex flex-col gap-3'}>
              <FormField
                control={form.control}
                name="firstName"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input placeholder={'First Name'} {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="lastName"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input placeholder={'Last Name'} {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="email"
                disabled
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        className="shadow-none"
                        placeholder={'Email'}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className={'flex justify-start gap-2'}>
                <Button
                  type={'submit'}
                  className={'w-15 px-3 h-8 text-sm'}
                  variant={'default'}
                  disabled={editIsPending}
                  loading={editIsPending}
                >
                  {editIsPending ? 'Saving...' : 'Save Changes'}
                </Button>
              </div>
            </div>
          </form>
        </Form>
      </div>
    </div>
  );
}
