import {
  LandingAdCard,
  LandingFeedAdCard,
} from "@/components/templates/LandingAdCard";
import { z } from "zod";
import { GetAdArgs } from "../../../../../../src/server/rpc";
import { useNavigate } from "@tanstack/react-router";
import { trpc } from "@/utils/trpc.ts";
import { ErrorDisplay } from "@/components/error.tsx";
import { Loader } from "@/components/custom-components/Loader";
import { showTemplateRecentButton } from "@/utils/data/feeds.ts";
import { StartFreeTrialAdCard } from "../AdGridView";
import useCardLayoutWithImpressions from "@/hooks/useCardLayoutWithImpressions";
import {
  MasonryComponent,
  TypedResponsiveMasonry,
} from "@/components/ResponsiveMasonryWrapper.tsx";

type props = {
  Cards: GenericCardProps[];
  HasAccess: boolean;
  type: "email" | "landing-page";
};

export type GenericCardProps = {
  Screenshot: string;
  ID: string;
  created: string;
  type?: "email" | "landing-page";
  category?: string;
  imageUrl?: string | null;
  isSaved?: boolean;
};

export const LandingAdGridView = (props: props) => {
  return (
    <div className={"grid grid-cols-1 lg:grid-cols-3 gap-6"}>
      {props?.Cards?.map((card) => (
        <LandingAdCard
          key={card.ID}
          adData={{
            Screenshot: card.Screenshot,
            ID: card.ID,
            created: card.created,
            category: card.category,
            isSaved: card.isSaved,
            imageUrl: card.imageUrl,
          }}
          showRecentButton={showTemplateRecentButton(card.created)}
          type={props.type}
        />
      ))}
    </div>
  );
};

type publicProps = z.infer<typeof GetAdArgs>;

export const LandingFeedGridViewPublic = (props: publicProps) => {
  // If a user is logged in, they don't need to see this screen
  const { data: authUser } = trpc.me.useQuery(null);
  const { squareRef, columns, gutterWidth } = useCardLayoutWithImpressions();
  const navigate = useNavigate();
  if (authUser) {
    navigate({
      to: "/feeds/inspiration/landing-pages/$adID",
      params: {
        adID: props.adId.toString(),
      },
    });
  }

  const { data, isError, isLoading, isRefetching } =
    trpc.previewLanderInFeed.useQuery(
      {
        landerId: props.adId,
      },
      {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      },
    );

  if (isError) {
    return (
      <div className={"px-10"}>
        <ErrorDisplay />
      </div>
    );
  }

  // isRefetching is particularly important to have here because the random filter will have different results
  // that shouldn't be cached or reused
  if (!data || isLoading || isRefetching) {
    return (
      <div
        className={"flex justify-center items-center w-full h-[70vh] m-auto"}
      >
        <Loader />
      </div>
    );
  }

  return (
    <div ref={squareRef} className={"relative w-full px-0 md:px-0 pb-10"}>
      {data && (
        <div>
          <TypedResponsiveMasonry
            columnsCountBreakPoints={columns ? { 0: columns } : {}} // Columns is determined by the width of the container
          >
            <MasonryComponent gutter={gutterWidth / 16 + "rem"}>
              {data && [
                data[0] && (
                  <LandingFeedAdCard
                    key={data[0].landerId}
                    adData={data[0]}
                    IsPublic={true}
                    IsLocked={false}
                  />
                ),

                <StartFreeTrialAdCard key="startFreeTrial" />,

                ...data
                  .slice(1)
                  .map((card) => (
                    <LandingFeedAdCard
                      key={card.landerId}
                      adData={card}
                      IsPublic={true}
                      IsLocked={true}
                    />
                  )),
              ]}
            </MasonryComponent>
          </TypedResponsiveMasonry>
        </div>
      )}
    </div>
  );
};
