import { forwardRef } from "react";
import { cva, VariantProps } from "class-variance-authority";
import { PolymorphicComponentPropsWithRef } from "@/utils/types";
import { cn } from "@/lib/utils.ts";

const textStyles = cva("w-full", {
  variants: {
    size: {
      xs: "text-xs leading-4",
      sm: "text-sm leading-5",
      base: "text-base leading-[1.21rem]",
      lg: "text-lg leading-[1.513rem]",
      xl: "text-xl leading-[1.816rem]",
      xxl: "text-lg lg:text-2xl",
      ["4xl"]: "text-lg lg:text-4xl leading-10",
    },
    weight: {
      thin: "font-thin",
      normal: "font-normal",
      medium: "font-medium",
      semibold: "font-semibold",
      bold: "font-bold",
    },
    align: {
      left: "text-left",
      center: "text-center",
      right: "text-right",
    },
    italic: {
      true: "italic",
    },
    underline: {
      true: "underline",
    },
  },
  defaultVariants: {
    size: "base",
    align: "left",
    weight: "normal",
  },
});

type TextProps<C extends React.ElementType> = {
  as?: C;
  className?: string;
} & PolymorphicComponentPropsWithRef<C, VariantProps<typeof textStyles>>;

export const Text = forwardRef(
  <C extends React.ElementType = "span">(
    {
      as,
      align,
      size,
      italic,
      underline,
      weight,
      className,
      ...props
    }: TextProps<C>,
    ref?: React.Ref<React.ElementType>,
  ) => {
    const Component = as || "span";

    return (
      <Component
        ref={ref}
        className={cn(
          textStyles({
            size,
            weight,
            italic,
            underline,
            align,
          }),
          className,
        )}
        {...props}
      />
    );
  },
) as <C extends React.ElementType = "span">(
  props: TextProps<C> & { ref?: React.Ref<React.ElementType> },
) => React.ReactElement | null;
