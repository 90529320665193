import { Drawer, DrawerContent } from "@/components/shadcn/drawer.tsx";
import { Ad } from "@/components/templates/DiscoverGridView";
import { trpc } from "@/utils/trpc.ts";
import { X } from "@mynaui/icons-react";
import { useState } from "react";
import { ScrollArea } from "@/components/shadcn/scroll-area.tsx";
import FreeAccessPrompt from "@/components/FreeAccessPrompt.tsx";
import { Loader } from "./custom-components/Loader";
import AdInspirationDetailsBody from "./AdInspirationDetailsBody";
import { AdInspirationDetailsMedia } from "./AdInspirationDetailsMedia";

export default function AdInspirationDetails({
  open,
  onOpenChange,
  data,
  IsPublic,
}: {
  open: boolean;
  onOpenChange: () => void;
  data: Ad;
  IsPublic: boolean;
}) {
  const [accessPromptDialogOpen, setAccessPromptDialogOpen] =
    useState<boolean>(false);
  const [saveOpen, setSaveOpen] = useState<boolean>(false);

  const {
    data: adCreativeDetails,
    isLoading,
  } = trpc.getAdCreativeDetails.useQuery(
    { adId: data.id },
    {
      enabled: open,
      refetchOnWindowFocus: false,
    }
  );

  const { data: numberOfActiveAdsForBrand } =
    trpc.getNumberOfActiveAdsForBrand.useQuery(
      { brandId: data.brandId || "" },
      {
        enabled: open,
      }
    );

  const { data: authUser } = trpc.me.useQuery(null, {
    refetchOnWindowFocus: false,
  });

  const { data: permissions } = trpc.permissions.useQuery(null, {});

  return (
    <Drawer open={open} onOpenChange={onOpenChange}>
      {accessPromptDialogOpen && (
        <FreeAccessPrompt
          open={accessPromptDialogOpen}
          onOpenChange={() => setAccessPromptDialogOpen(false)}
        />
      )}


  
      {isLoading ? (
        <DrawerContent className={"h-[85vh] w-full"}>
          <div className="flex flex-col justify-center items-center w-full h-[85vh]">
            <Loader />
          </div>
        </DrawerContent>
      ) : (
        <DrawerContent
          className={"h-[90vh] p-1 flex flex-col-reverse lg:flex-row w-full"}
          onInteractOutside={saveOpen ? (e) => e.preventDefault() : undefined}
        >
          <ScrollArea
            className={"bg-themeaccent rounded h-full w-full hidden lg:flex"}
          >
            <AdInspirationDetailsMedia
              adCreativeDetails={adCreativeDetails}
              data={data}
              numberOfActiveAdsForBrand={numberOfActiveAdsForBrand}
              saveOpen={saveOpen}
              setSaveOpen={setSaveOpen}
              IsPublic={IsPublic}
            />
          </ScrollArea>
          <div className="hidden lg:flex">
            <div className="flex flex-col rounded lg:h-full w-full lg:w-[28rem]">
              <div className={"flex justify-end h-4 min-h-4"}>
                <span onClick={onOpenChange} className={"p-1 cursor-pointer"}>
                  <X className="w-4 h-4 min-w-4" />
                </span>
              </div>

              <ScrollArea className="h-full">
                <AdInspirationDetailsBody
                  data={data}
                  IsPublic={IsPublic}
                  setAccessPromptDialogOpen={setAccessPromptDialogOpen}
                  adCreativeDetails={adCreativeDetails}
                  authUser={authUser}
                  permissions={permissions}
                />
              </ScrollArea>
            </div>
          </div>
          <ScrollArea className="flex lg:hidden h-full">
            <div className="flex flex-col rounded lg:h-full w-full lg:max-w-[28rem]">
              <div className={"flex justify-end h-4 min-h-4"}>
                <span onClick={onOpenChange} className={"p-1 cursor-pointer"}>
                  <X className="w-4 h-4 min-w-4" />
                </span>
              </div>
              <AdInspirationDetailsBody
                data={data}
                setAccessPromptDialogOpen={setAccessPromptDialogOpen}
                adCreativeDetails={adCreativeDetails}
                authUser={authUser}
                permissions={permissions}
                IsPublic={IsPublic}
                mediaComponent={
                  <ScrollArea
                    className={
                      "bg-themeaccent rounded h-full w-full lg:min-h-[65vh]"
                    }
                  >
                    <AdInspirationDetailsMedia
                      adCreativeDetails={adCreativeDetails}
                      data={data}
                      numberOfActiveAdsForBrand={numberOfActiveAdsForBrand}
                      saveOpen={saveOpen}
                      setSaveOpen={setSaveOpen}
                      IsPublic={IsPublic}
                    />
                  </ScrollArea>
                }
              />
            </div>
          </ScrollArea>
        </DrawerContent>
      )}
    </Drawer>
  );
}
