import { Drawer, DrawerContent } from "@/components/shadcn/drawer.tsx";
import { X } from "@mynaui/icons-react";
import { useState } from "react";
import { trpc } from "@/utils/trpc.ts";
import FreeAccessPrompt from "@/components/FreeAccessPrompt.tsx";
import { ScrollArea } from "@/components/shadcn/scroll-area.tsx";
import { EmailFeedContent } from "../../../../src/server/services/emails/definitions";
import { Loader } from "./custom-components/Loader";
import { EmailInspirationDetailsMedia } from "./EmailInspirationDetailsMedia";
import EmailInspirationDetailsBody from "./EmailInspirationDetailsBody";

export default function EmailInspirationDetails({
  open,
  onOpenChange,
  data,
}: {
  open: boolean;
  onOpenChange: () => void;
  data: EmailFeedContent;
  IsPublic: boolean;
}) {
  const [accessPromptDialogOpen, setAccessPromptDialogOpen] =
    useState<boolean>(false);

  const { data: authUser } = trpc.me.useQuery(null, {
    refetchOnWindowFocus: false,
  });

  const [saveOpen, setSaveOpen] = useState<boolean>(false);
  const { data: permissions } = trpc.permissions.useQuery(null, {});

  const {
    data: emailDetails,
    isLoading,
  } = trpc.getEmailById.useQuery(
    {
      id: data.id,
    },
    {
      refetchOnMount: true,
    }
  );

  return (
    <Drawer open={open} onOpenChange={onOpenChange}>
      {accessPromptDialogOpen && (
        <FreeAccessPrompt
          open={accessPromptDialogOpen}
          onOpenChange={() => setAccessPromptDialogOpen(false)}
        />
      )}

      {isLoading ? (
        <DrawerContent className={"h-[85vh] w-full"}>
          <div className="flex flex-col justify-center items-center w-full h-[85vh]">
            <Loader />
          </div>
        </DrawerContent>
      ) : (
        <DrawerContent
          className={"h-[90vh] p-1 flex flex-col-reverse lg:flex-row w-full"}
          onInteractOutside={saveOpen ? (e) => e.preventDefault() : undefined}
        >
          <ScrollArea
            className={"bg-themeaccent rounded h-full w-full hidden lg:flex"}
          >
            <EmailInspirationDetailsMedia
              data={emailDetails ?? data}
              saveOpen={saveOpen}
              setSaveOpen={setSaveOpen}
            />
          </ScrollArea>
          <div className="hidden lg:flex">
            <div className="flex flex-col rounded lg:h-full w-full lg:w-[28rem]">
              <div className={"flex justify-end h-4 min-h-4"}>
                <span onClick={onOpenChange} className={"p-1 cursor-pointer"}>
                  <X className="w-4 h-4 min-w-4" />
                </span>
              </div>

              <ScrollArea className="h-full">
                <EmailInspirationDetailsBody
                  data={data}
                  setAccessPromptDialogOpen={setAccessPromptDialogOpen}
                  authUser={authUser}
                  permissions={permissions}
                />
              </ScrollArea>
            </div>
          </div>
          <ScrollArea className="flex lg:hidden h-full">
            <div className="flex flex-col rounded lg:h-full w-full lg:max-w-[28rem]">
              <div className={"flex justify-end h-4 min-h-4"}>
                <span onClick={onOpenChange} className={"p-1 cursor-pointer"}>
                  <X className="w-4 h-4 min-w-4" />
                </span>
              </div>

              <EmailInspirationDetailsBody
                data={data}
                setAccessPromptDialogOpen={setAccessPromptDialogOpen}
                authUser={authUser}
                permissions={permissions}
                mediaComponent={
                  <ScrollArea
                    className={
                      "bg-themeaccent rounded h-full w-full lg:min-h-[65vh]"
                    }
                  >
                    <EmailInspirationDetailsMedia
                      data={emailDetails ?? data}
                      saveOpen={saveOpen}
                      setSaveOpen={setSaveOpen}
                    />
                  </ScrollArea>
                }
              />
            </div>
          </ScrollArea>
        </DrawerContent>
      )}
    </Drawer>
  );
}
