import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { Button } from '@/components/shadcn/button.tsx';
import { PRO_PLAN, STANDARD_PLAN } from '@/utils/data/plans.ts';
import { ProtectedArea } from '@/components/protectedArea.tsx';
import OnboardingSteppers from '@/components/onboarding/OnboardingSteppers.tsx';
import OnboardingHeader from '@/components/onboarding/OnboardingHeader.tsx';
import { Tabs, TabsList, TabsTrigger } from '@/components/shadcn/tabs.tsx';
import { CheckCircle } from '@mynaui/icons-react';
import { Badge } from '@/components/shadcn/badge.tsx';
import { useState } from 'react';

export const Route = createFileRoute('/plans')({
  component: Plans,
});

function Plans() {
  const navigate = useNavigate();
  const [period, setPeriod] = useState<'monthly' | 'annually'>('monthly');

  function checkoutPlan(selectedPlan: string) {
    if (selectedPlan) {
      localStorage.setItem('cos_plan', selectedPlan); // Store selected plan in localStorage
      setTimeout(() => {
        navigate({ to: '/checkout' });
      }, 500);
    }
  }

  return (
    <ProtectedArea>
      <div
        className={'bg-brandgrad bg-no-repeat bg-center bg-cover min-h-screen'}
      >
        <div>
          <OnboardingHeader />
        </div>
        <div
          className={
            'flex-1 flex flex-col justify-center items-center py-[7.75rem]'
          }
        >
          <div
            className={
              'rounded-lg bg-white p-5 w-11/12 lg:max-w-2xl flex flex-col gap-6 border'
            }
          >
            <div className={'flex flex-col items-start gap-6'}>
              <OnboardingSteppers stepIndex={0} />
              <h4
                className={
                  'text-2xl text-center text-themeforeground font-semibold'
                }
              >
                Select your plan
              </h4>
            </div>

            <div
              className={
                'flex flex-col md:flex-row justify-between gap-3 items-center'
              }
            >
              <p className={'text-sm'}>
                <span className={'font-light'}>All plans include a</span>{' '}
                <span className={'font-semibold'}>3-Day Free Trial.</span>
              </p>
              <Tabs defaultValue={period}>
                <TabsList className={'rounded-full'}>
                  <TabsTrigger
                    className={'rounded-full'}
                    value={'monthly'}
                    onClick={() => setPeriod('monthly')}
                  >
                    Pay Monthly
                  </TabsTrigger>
                  <TabsTrigger
                    className={'rounded-full flex gap-1.5'}
                    value={'annually'}
                    onClick={() => setPeriod('annually')}
                  >
                    <span>Pay Yearly</span>{' '}
                    <span className={'text-themedestructive'}>Save 50%</span>
                  </TabsTrigger>
                </TabsList>
              </Tabs>
            </div>
            <div className={'grid grid-cols-1 lg:grid-cols-2 gap-3'}>
              {STANDARD_PLAN && (
                <div className={'space-y-3 p-4 border-2 rounded-lg'}>
                  <div>
                    <h3 className={'font-medium'}>{STANDARD_PLAN.name}</h3>
                    <p className={'mt-1'}>
                      <span className={'text-2xl font-semibold'}>
                        {period === 'monthly'
                          ? STANDARD_PLAN.price
                          : STANDARD_PLAN.annual_monthly_price}
                      </span>{' '}
                      / month, billed {period}
                    </p>
                    <p
                      className={'text-sm font-light text-thememutedforeground'}
                    >
                      {period === 'monthly' ? (
                        'Save 50% when billed annually'
                      ) : (
                        <span className={'line-through'}>
                          {STANDARD_PLAN.price} / billed monthly
                        </span>
                      )}
                    </p>
                  </div>
                  <Button
                    size={'sm'}
                    className={'w-full'}
                    onClick={() =>
                      checkoutPlan(
                        period === 'monthly'
                          ? STANDARD_PLAN.plan_id
                          : STANDARD_PLAN.yearly_plan_id,
                      )
                    }
                  >
                    Select Plan
                  </Button>
                  <div>
                    <p
                      className={'text-sm font-light text-thememutedforeground'}
                    >
                      {STANDARD_PLAN.summary}
                    </p>
                    <ul className={'space-y-3 mt-2'}>
                      {STANDARD_PLAN.features.map((feature, index) => (
                        <li
                          key={index}
                          className="flex gap-1 justify-start items-center text-thememutedforeground"
                        >
                          <CheckCircle className="w-4 h-4" />
                          <span className="text-sm font-normal leading-5 ">
                            {feature}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
              {PRO_PLAN && (
                <div
                  className={
                    'space-y-3 p-4 border-2 border-themedestructive rounded-lg'
                  }
                >
                  <div>
                    <div className={'flex gap-2 items-center'}>
                      <h3 className={'font-medium'}>{PRO_PLAN.name}</h3>
                      <Badge variant={'secondaryDestructive'}>
                        Recommended
                      </Badge>
                    </div>
                    <p>
                      <span className={'text-2xl font-semibold'}>
                        {period === 'monthly'
                          ? PRO_PLAN.price
                          : PRO_PLAN.annual_monthly_price}
                      </span>{' '}
                      / month, billed {period}
                    </p>
                    <p
                      className={'text-sm font-light text-thememutedforeground'}
                    >
                      {period === 'monthly' ? (
                        'Save 50% when billed annually'
                      ) : (
                        <span className={'line-through'}>
                          {PRO_PLAN.price} / billed monthly
                        </span>
                      )}
                    </p>
                  </div>
                  <Button
                    variant={'destructivePurple'}
                    size={'sm'}
                    className={'w-full'}
                    onClick={() =>
                      checkoutPlan(
                        period === 'monthly'
                          ? PRO_PLAN.plan_id
                          : PRO_PLAN.yearly_plan_id,
                      )
                    }
                  >
                    Select Plan
                  </Button>
                  <div>
                    <p
                      className={'text-sm font-light text-thememutedforeground'}
                    >
                      {PRO_PLAN.summary}
                    </p>
                    <ul className={'space-y-3 mt-2'}>
                      {PRO_PLAN.features.map((feature, index) => (
                        <li
                          key={index}
                          className="flex gap-1 justify-start items-center text-thememutedforeground"
                        >
                          <CheckCircle className="w-4 h-4" />
                          <span className="text-sm font-normal leading-5 ">
                            {feature}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </ProtectedArea>
  );
}
