import { createFileRoute, useNavigate } from "@tanstack/react-router";

import { trpc } from "@/utils/trpc.ts";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ErrorDisplay } from "@/components/error.tsx";
import {
  GenericCardProps,
  LandingAdGridView,
} from "@/components/templates/LandingAdGridView";
import { Loader } from "@/components/custom-components/Loader";
import { Stack, Text } from "@/components/custom-components";
import { z } from "zod";
import { orderFilter as OrderFilterTypes } from "../../../../src/shared/airtable/index.ts";
import { FeatureTabs } from "@/components/custom-components/FeatureTabs/index.tsx";
import { OrderFilterSelect } from "@/components/ad-inspiration/OrderFilterSelect.tsx";
import { FilterOption } from "./feeds.templates.index.tsx";
import FreeTrialEnding from "@/components/FreeTrialEnding.tsx";
import TemplatesFilterPopover from "@/components/custom-components/TemplateFilterPopover";
import { Shuffle, TrendingUp } from "@mynaui/icons-react";
import { Button } from "@/components/shadcn/button.tsx";
import { AdFeedFilterOption } from "@/hooks/useFilterFeed.tsx";

type SearchParams = {
  cacheBuster?: number;
  loadAdsCreatedAfter?: number;
  sideBarOpen?: boolean;
  categories?: string;
  orderFilter?: z.infer<typeof OrderFilterTypes>;
};

export type SelectedTemplateFilters = {
  categories?: string[];
};

export const Route = createFileRoute("/feeds/templates/landing-pages/")({
  component: All,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const cacheBuster = (search?.cacheBuster as number) ?? Math.random();
    const sideBarOpen = search?.sideBarOpen as boolean;
    const orderFilter = search?.orderFilter as
      | z.infer<typeof OrderFilterTypes>
      | undefined;

    return {
      cacheBuster: cacheBuster,
      sideBarOpen,
      orderFilter,
    };
  },
});

function All() {
  const {
    cacheBuster,
    orderFilter,
    categories: queryCategories,
  } = Route.useSearch();
  const navigate = useNavigate();

  const { data: newlyAddedTemplateCount } =
    trpc.getNewlyAddedTemplatesCount.useQuery(
      { templatesType: "landing-page", daysToCheck: 30 },
      {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
      },
    );

  // Get the template collections to be passed to the filter
  const { data: landingCategories } =
    trpc.getLandingPageTemplateCategories.useQuery();
  const [filterOptions, setFilterOptions] = useState<FilterOption[]>([]);
  const [selectedFilters, setSelectedFilters] =
    useState<SelectedTemplateFilters>({
      categories: queryCategories ? queryCategories.split(",") : undefined,
    });

  const updateQueryString = useCallback(
    (params: { categories?: string }) => {
      navigate({
        to: "/feeds/templates/landing-pages",
        replace: true,
        search: (old) => {
          const newSearch = {
            ...old,
            ...params,
            orderFilter,
            cacheBuster: Math.random(),
          };
          return JSON.stringify(newSearch) === JSON.stringify(old)
            ? old
            : newSearch; // Prevent redundant updates
        },
      });
    },
    [navigate, orderFilter],
  );

  const handleOptionsChange = useCallback(
    (options: AdFeedFilterOption[]) => {
      const selectedCategories: string[] = [];

      options.forEach((group) => {
        group.optionItems.forEach((item) => {
          if (item.value) {
            if (group.title === "Category") selectedCategories.push(item.label);
          }
        });
      });

      setSelectedFilters({
        categories:
          selectedCategories.length > 0 ? selectedCategories : undefined,
      });

      updateQueryString({
        categories:
          selectedCategories.length > 0
            ? selectedCategories.join(",")
            : undefined,
      });
    },
    [updateQueryString],
  );

  // Update AdFilter options based on selectedFilters
  useEffect(() => {
    if (landingCategories && filterOptions.length === 0) {
      setFilterOptions((prevOptions) => {
        if (prevOptions.length > 0) return prevOptions; // Prevent redundant updates
        return [
          {
            title: "Category",
            counter: 0,
            optionItems: landingCategories.map((i) => ({
              label: i.category,
              value: false,
            })),
          },
        ];
      });
    }
  }, [landingCategories, filterOptions.length]);

  useEffect(() => {
    if (landingCategories && filterOptions.length === 0) {
      setFilterOptions((prevOptions) => {
        if (prevOptions.length > 0) return prevOptions; // Prevent redundant updates
        return [
          {
            title: "Category",
            counter: 0,
            optionItems: landingCategories.map((i) => ({
              label: i.category,
              value: false,
            })),
          },
        ];
      });
    }
  }, [landingCategories, filterOptions.length]);

  const {
    data: landingPages,
    refetch,
    isLoading,
    error,
  } = trpc.landingPages.useQuery(
    {
      cacheBuster: cacheBuster,
      sortingOptions: orderFilter,
      categories: selectedFilters.categories,
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  );

  const [upgradeDialogOpen, setUpgradeDialogOpen] = useState<boolean>(false);

  const { data: permissionData, isLoading: isLoadingPermission } =
    trpc.permissions.useQuery(null, {});

  const formatData = useMemo((): GenericCardProps[] => {
    if (!landingPages) return [];
    const returnData = [] as GenericCardProps[];
    landingPages.LandingPages?.map((datum) => {
      returnData.push({
        Screenshot: datum["Landing Page Screenshot"],
        ID: datum["LP ID"],
        type: "landing-page",
        created: datum.Created,
        isSaved: datum.isSaved,
        imageUrl: datum.imageUrl,
      });
    });
    return returnData;
  }, [landingPages]);

  useEffect(() => {
    if (
      !isLoadingPermission &&
      permissionData?.userCanAccessEverything === false &&
      !upgradeDialogOpen
    ) {
      setUpgradeDialogOpen(true); // Only update if `upgradeDialogOpen` is false
    }
  }, [
    permissionData?.userCanAccessEverything,
    isLoadingPermission,
    upgradeDialogOpen,
  ]);

  return (
    <>
      {upgradeDialogOpen && permissionData && (
        <FreeTrialEnding
          open={upgradeDialogOpen}
          onOpenChange={() => setUpgradeDialogOpen(false)}
          permissionData={permissionData}
        />
      )}
      <Stack className="gap-3 lg:gap-6">
        <Stack className="gap-3 lg:gap-8">
          <FeatureTabs
            tabItems={[
              {
                name: "Ads",
                link: "/feeds/templates",
              },
              {
                name: "Emails",
                link: "/feeds/templates/emails",
                isPremiumFeature: true,
              },
              {
                name: "Landers",
                link: "/feeds/templates/landing-pages",
                isPremiumFeature: true,
              },
            ]}
          />
          <Stack className="gap-3 lg:gap-6">
            <div className={"flex justify-between gap-5 items-center"}>
              <Text weight="semibold" size={"xxl"} className="w-fit">
                Lander Templates
              </Text>

              <div className={"flex gap-2 items-center flex-wrap justify-end"}>
                {(newlyAddedTemplateCount ?? 0) > 0 && (
                  <div className="flex gap-2 w-fit items-center">
                    <TrendingUp className="text-themedestructive" />
                    <Text
                      size={"sm"}
                      weight={"medium"}
                      className="text-thememutedforeground "
                    >
                      {newlyAddedTemplateCount} New{" "}
                      {newlyAddedTemplateCount === 1 ? "Template" : "Templates"}
                    </Text>
                  </div>
                )}
                <div className="lg:hidden">
                  <OrderFilterSelect
                    defaultFilter={orderFilter || "Random"}
                    options={["Random", "Recent", "Popular", "Oldest"]}
                  />
                </div>
                <Button
                  size={"sm"}
                  className="h-8"
                  variant={"brandGradient"}
                  onClick={() => {
                    if (orderFilter !== "Random") {
                      navigate({
                        search: (old) => {
                          return {
                            ...old,
                            orderFilter: "Random",
                          };
                        },
                        params: (old) => {
                          return {
                            ...old,
                          };
                        },
                      });
                    } else {
                      refetch();
                    }
                  }}
                >
                  <Shuffle className="h-5" />
                  Shuffle
                </Button>
              </div>
            </div>
          </Stack>
        </Stack>

        <div
          className={`lg:sticky lg:top-0 lg:z-10 lg:bg-white lg:py-2 flex ${filterOptions && filterOptions.length > 0 ? "justify-between" : "justify-end"} items-center`}
        >
          <div>
            {filterOptions && filterOptions.length > 0 && (
              <TemplatesFilterPopover
                initialOptions={filterOptions}
                onOptionsChange={handleOptionsChange}
                placeholder={"Filter Ad Templates"}
              />
            )}
          </div>
          <div className={"hidden lg:flex"}>
            <OrderFilterSelect
              defaultFilter={orderFilter || "Random"}
              options={["Random", "Recent", "Popular", "Oldest"]}
            />
          </div>
        </div>

        {isLoading ? (
          <div
            className={
              "flex justify-center items-center w-full h-screen m-auto"
            }
          >
            <Loader />
          </div>
        ) : error ? (
          <ErrorDisplay />
        ) : (
          <LandingAdGridView
            Cards={formatData}
            HasAccess={landingPages ? landingPages.HasAccess : false}
            type={"landing-page"}
          />
        )}
      </Stack>
    </>
  );
}
